import { observer } from "mobx-react";
import moment from "moment";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import style from "src/shared/components/allAccrualsTable/AllAccrualsTable.module.css";
import { useRootStore } from "src/shared/stores/initStore";

const AllAccrualsContent = observer(({ PaginationComponent }) => {
  const { allAccrualsStore, authStore } = useRootStore();
  const navigate = useNavigate();
  const userRole = authStore.userData?.profile?.role;

  const handleRowClick = async (employeeId) => {
    const { selectedDate } = allAccrualsStore;
    await allAccrualsStore.loadEmployeeAccruals(
      employeeId,
      moment(selectedDate).format("YYYY-MM-DD"),
    );
    navigate(`/accruals/employee/${employeeId}`);
  };

  const tableTitleClass =
    userRole === "accountant"
      ? style.tableTitleAccountant
      : style.tableTitleDepHead;

  return (
    <>
      <Table striped bordered hover className={style.tableAllAccrualsContent}>
        <tbody>
          {allAccrualsStore.accruals.map((accrual) => (
            <tr
              key={accrual.employee.id + accrual.department.id}
              onClick={() => handleRowClick(accrual.employee.id)}
            >
              <td className={tableTitleClass}>
                <span>{accrual.date}</span>
                <span>{accrual.employee.fullname}</span>
                <span>{accrual.employee.job_title || "-"}</span>
                <span>{accrual.department.title}</span>
                <span>{accrual.bets_amount.toLocaleString()}</span>
                <span>{accrual.salary.toLocaleString()}</span>
                <span>{accrual.deal_charges_amount.toLocaleString()}</span>
                {userRole === "accountant" ? (
                  <>
                    <span>{accrual.regular_surcharges_amount.toLocaleString()}</span>
                    <span>{accrual.once_surcharges_amount.toLocaleString()}</span>
                  </>
                ) : (
                  <span>{accrual.surcharges_amount.toLocaleString()}</span>
                )}
                <span>{accrual.total_sum.toLocaleString()}</span>
                {userRole === "accountant" && (
                  <>
                    <span>{accrual.tax_sum.toLocaleString()}</span>
                    <span>{accrual.sum_with_tax.toLocaleString()}</span>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {PaginationComponent}
    </>
  );
});

export default AllAccrualsContent;
