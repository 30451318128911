import React, { useEffect, useState, Suspense } from "react";

import { observer } from "mobx-react";
import { Dropdown, Table, FormControl, InputGroup } from "react-bootstrap";

import { icTrash } from "src/assets/icons/icons";
import style from "src/pages/userManagment/index.module.css";
import { CustomButton, PaginationComponent } from "src/shared";
import Loader from "src/shared/components/loaders/Loader";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useRootStore } from "src/shared/stores/initStore";
import ConfirmationModal from "src/shared/ui/modals/modalConfirm/ConfirmationModal";
import { PageWrapper } from "src/shared/ui/pageWrapper";

export const UserManagment = observer(() => {
  const { usersStore } = useRootStore();
  const AddUserModal = React.lazy(
    () => import("src/shared/ui/modals/usersManagment/AddUserModal"),
  );
  const EditUserModal = React.lazy(
    () => import("src/shared/ui/modals/usersManagment/EditUserModal"),
  );

  // УДАЛЕНИЕ
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentFormId, setCurrentFormId] = useState(null);
  const [currentFormTitle, setCurrentFormTitle] = useState("");
  // ДОБАВЛЕНИЕ
  const [showAddModal, setShowAddModal] = useState(false);
  // РЕДАКТИРОВАНИЕ
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  // ЗАГРУЗКА ТАБЛИЦЫ И ИМЯ СТР
  useEffect(() => {
    document.title = "Пользователи - Physical";
    usersStore.loadUsers();
  }, [usersStore]);

  useEffect(() => {
    usersStore.setSearchTerm(debouncedSearchTerm);
    usersStore.loadUsers();
  }, [debouncedSearchTerm, usersStore]);

  // ДОБАВЛЕНИЕ
  const handleAddUser = (userData) => {
    usersStore.addUser(userData).then(() => {
      if (!usersStore.error) {
        setShowAddModal(false);
        usersStore.loadUsers();
        usersStore.clearError();
      }
    });
  };

  // РЕДАКТИРОВАНИЕ
  const closeEditModal = () => setShowEditModal(false);
  const openEditModal = (user) => {
    setCurrentUser(user);
    setShowEditModal(true);
  };

  // УДАЛЕНИЕ
  const handleDelete = (e, id, title) => {
    e.stopPropagation();
    setCurrentFormId(id);
    setCurrentFormTitle(title);
    setShowDeleteModal(true);
  };
  const confirmDelete = () => {
    usersStore.deleteUser(currentFormId);
    setShowDeleteModal(false);
  };

  // ФОРМАТИРОВАНИЕ ДАТЫ СОЗДАНИЯ ЮЗЕРА
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("ru-RU");
  }

  const handlePageChange = (page) => {
    usersStore.setCurrentPage(page);
    usersStore.loadUsers();
  };

  const handlePageSizeChange = (size) => {
    usersStore.setPageSize(size);
    usersStore.setCurrentPage(1);
    usersStore.loadUsers();
  };

  const handleSelectRole = (role) => {
    usersStore.setSelectedRole(role);
  };

  const getRoleDisplay = (user) => {
    if (user.superuser) return "Администратор";
    return usersStore.userRoles[user.profile.role];
  };

  return (
    <PageWrapper>
      <h1 className={style.titleHeader}>Пользователи</h1>

      <div className={style.headContainer}>
        <CustomButton onClick={() => setShowAddModal(true)} disabled={false}>
          Добавить пользователя
        </CustomButton>
        <div className={style.rightGroup}>
          <Dropdown onSelect={handleSelectRole}>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className={style.dropdownContainer}
            >
              {usersStore.selectedRole
                ? getRoleDisplay({ profile: { role: usersStore.selectedRole } })
                : "Выберите роль"}
            </Dropdown.Toggle>
            <Dropdown.Menu className={style.scrollableDropdownMenu}>
              <Dropdown.Item eventKey="department_head">
                Руководитель отдела
              </Dropdown.Item>
              <Dropdown.Item eventKey="accountant">Бухгалтер</Dropdown.Item>
              <Dropdown.Item eventKey="hr_manager">Кадровик</Dropdown.Item>
              <Dropdown.Item eventKey="">Без фильтра</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <FormControl
            className={style.searchInput}
            type="text"
            placeholder="Поиск"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className={style.tableScrollWrapper}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className={style.tableHead}>
                <span>ФИО</span>
                <span>Роль</span>
                <span>Дата создания</span>
                <span>{` `}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {usersStore.users.map((user) => (
              <tr key={user.id}>
                <td
                  className={style.tableTitle}
                  onClick={() => openEditModal(user)}
                >
                  <span>{user.profile.full_name}</span>
                  <span>{getRoleDisplay(user)}</span>
                  <span>{formatDate(user.created_at)}</span>
                  <button
                    type="button"
                    className={style.buttonBackground}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(e, user.id, user.profile.full_name);
                    }}
                  >
                    <img
                      className={style.iconTrash}
                      src={icTrash}
                      alt="delete_item"
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <PaginationComponent
          itemsPerPage={usersStore.pageSize}
          totalItems={usersStore.totalUsers}
          paginate={handlePageChange}
          currentPage={usersStore.currentPage}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>

      <Suspense fallback={<Loader />}>
        <AddUserModal
          show={showAddModal}
          onHide={() => setShowAddModal(false)}
          onSave={handleAddUser}
        />

        <EditUserModal
          show={showEditModal}
          onHide={closeEditModal}
          user={currentUser}
        />
      </Suspense>

      <ConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={confirmDelete}
        title="Подтверждение удаления"
      >
        Вы действительно хотите удалить {currentFormTitle}?
      </ConfirmationModal>
    </PageWrapper>
  );
});
