import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import moment from "moment";
import { Form, FormControl, Stack } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";

import {
  AllAccrualsTable,
  CustomButton,
  PaginationComponent,
  useDebounce,
  useSetTitle,
} from "src/shared";
import { useRootStore } from "src/shared/stores/initStore";

import style from "./AllAccruals.module.css";
import { AllAccrualsContent } from "./AllAccrualsContent";
import { CustomDropdown } from "./CustomDropdown";
import { ReportAddModal, useReportsModal } from "./ReportAddModal";
import { thAllAccrualsArray, thAllAccrualsArrayAccountant } from "../lib";

export const AllAccruals = observer(() => {
  useSetTitle("Начисления - Все начисления");
  const {
    departamentStore,
    allAccrualsStore,
    paymentFormsStore,
    employmentFormsStore,
    authStore,
  } = useRootStore();
  const { isShow, handleOpen, handleClose } = useReportsModal();
  const [filtersVisible, setFiltersVisible] = useState(false);
  const debouncedSearchTerm = useDebounce(allAccrualsStore.searchTerm, 200);
  const userRole = authStore.userData?.profile?.role;
  const location = useLocation();
  const { month, year, department, dateSelected } = location.state || {};

  useEffect(() => {
    if (month && year && department) {
      const date = new Date(year, month - 1);
      allAccrualsStore.setSelectedDate(date);
      allAccrualsStore.setSelectedDepartments(department);
      allAccrualsStore.loadAccruals();
    }
  }, [month, year, department, dateSelected]);

  useEffect(() => {
    departamentStore.setPageSize(100);
    departamentStore.loadDepartaments();
    if (userRole === "accountant") {
      paymentFormsStore.loadForms();
      employmentFormsStore.loadEmployments();
    }
  }, [departamentStore, paymentFormsStore, employmentFormsStore]);

  useEffect(() => {
    allAccrualsStore.loadAccruals();
  }, [debouncedSearchTerm, allAccrualsStore]);

  const handleDepartmentToggle = (deptId) => {
    const newSelectedDepartments =
      allAccrualsStore.selectedDepartments.includes(deptId)
        ? allAccrualsStore.selectedDepartments.filter((id) => id !== deptId)
        : [...allAccrualsStore.selectedDepartments, deptId];

    allAccrualsStore.setSelectedDepartments(newSelectedDepartments);
    allAccrualsStore.loadAccruals();
  };

  const handleDepartmentReset = () => {
    allAccrualsStore.setSelectedDepartments([]);
    allAccrualsStore.loadAccruals();
  };

  const handlePaymentFormToggle = (formId) => {
    const newSelectedPaymentForms =
      allAccrualsStore.selectedPaymentForms.includes(formId)
        ? allAccrualsStore.selectedPaymentForms.filter((id) => id !== formId)
        : [...allAccrualsStore.selectedPaymentForms, formId];

    allAccrualsStore.setSelectedPaymentForms(newSelectedPaymentForms);
    allAccrualsStore.loadAccruals();
  };

  const handlePaymentFormReset = () => {
    allAccrualsStore.setSelectedPaymentForms([]);
    allAccrualsStore.loadAccruals();
  };

  const handleEmploymentFormToggle = (formId) => {
    const newSelectedEmploymentForms =
      allAccrualsStore.selectedEmploymentForms.includes(formId)
        ? allAccrualsStore.selectedEmploymentForms.filter((id) => id !== formId)
        : [...allAccrualsStore.selectedEmploymentForms, formId];

    allAccrualsStore.setSelectedEmploymentForms(newSelectedEmploymentForms);
    allAccrualsStore.loadAccruals();
  };

  const handleEmploymentFormReset = () => {
    allAccrualsStore.setSelectedEmploymentForms([]);
    allAccrualsStore.loadAccruals();
  };

  const handleSearchTermChange = (e) => {
    allAccrualsStore.setSearchTerm(e.target.value);
  };

  const handlePageChange = (page) => {
    allAccrualsStore.setCurrentPage(page);
    allAccrualsStore.loadAccruals();
  };

  const handlePageSizeChange = (size) => {
    allAccrualsStore.setPageSize(size);
    allAccrualsStore.setCurrentPage(1);
    allAccrualsStore.loadAccruals();
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(moment(e.target.value, "YYYY-MM"));
    allAccrualsStore.setSelectedDate(selectedDate);
    allAccrualsStore.loadAccruals();
  };

  const departments = departamentStore.departaments.map((dept) => ({
    id: dept.id,
    label: dept.title,
  }));

  const paymentForms = paymentFormsStore.forms.map((form) => ({
    id: form.id,
    label: form.title,
  }));

  const employmentForms = employmentFormsStore.employments.map((form) => ({
    id: form.id,
    label: form.title,
  }));

  const extraColumns =
    userRole === "accountant" ? ["Налог", "Сумма с налогом"] : [];
  
  const columns = userRole === "accountant" ? thAllAccrualsArrayAccountant : thAllAccrualsArray;

  const toggleFilters = () => setFiltersVisible((prev) => !prev);

  return (
    <>
      <Stack gap={3}>
        <div className={style.buttonsContainer}>
          <div className={style.buttons}>
            <CustomButton onClick={handleOpen}>Сформировать отчёт</CustomButton>
            <CustomButton onClick={toggleFilters}>
              {filtersVisible ? "Скрыть фильтры" : "Показать фильтры"}
            </CustomButton>
          </div>

          {filtersVisible && (
            <div className={style.filtersContainer}>
              <CustomDropdown
                controlId="departmentSelect"
                selectedItems={allAccrualsStore.selectedDepartments}
                items={departments}
                onToggleSelection={handleDepartmentToggle}
                onReset={handleDepartmentReset}
                dropdownLabel="Отделы"
              />

              {userRole === "accountant" && (
                <>
                  <CustomDropdown
                    controlId="paymentFormSelect"
                    selectedItems={allAccrualsStore.selectedPaymentForms}
                    items={paymentForms}
                    onToggleSelection={handlePaymentFormToggle}
                    onReset={handlePaymentFormReset}
                    dropdownLabel="Формы выплат"
                  />

                  <CustomDropdown
                    controlId="employmentFormSelect"
                    selectedItems={allAccrualsStore.selectedEmploymentForms}
                    items={employmentForms}
                    onToggleSelection={handleEmploymentFormToggle}
                    onReset={handleEmploymentFormReset}
                    dropdownLabel="Формы ТУ"
                  />
                </>
              )}

              <Form.Control
                type="month"
                value={moment(allAccrualsStore.selectedDate).format("YYYY-MM")}
                onChange={handleDateChange}
                onClick={(e) => e.target.showPicker()}
                className={style.datePicker}
              />
              <div className={style.rigthSide}>
                <FormControl
                  className={style.inputSearch}
                  type="text"
                  placeholder="Поиск"
                  value={allAccrualsStore.searchTerm}
                  onChange={handleSearchTermChange}
                />
              </div>
              <div className={style.leftSide}>
                <CustomButton onClick={() => allAccrualsStore.resetFilters()}>
                  Сбросить фильтры
                </CustomButton>
              </div>
            </div>
          )}
        </div>
        <AllAccrualsTable
          thArray={columns}
          extraColumns={extraColumns}
          userRole={userRole}
        >
          <AllAccrualsContent
            PaginationComponent={
              <PaginationComponent
                itemsPerPage={allAccrualsStore.pageSize}
                totalItems={allAccrualsStore.totalAccruals}
                paginate={handlePageChange}
                currentPage={allAccrualsStore.currentPage}
                onPageSizeChange={handlePageSizeChange}
              />
            }
          />
        </AllAccrualsTable>
      </Stack>
      <ReportAddModal show={isShow} handleClose={handleClose} report={null} />
    </>
  );
});

export default AllAccruals;
