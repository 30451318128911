export const thAllAccrualsArrayAccountant = [
  "Дата",
  "Сотрудник",
  "Должность",
  "Отдел",
  "Сумма ставок",
  "Оклад",
  "Сделки",
  "Постоянные доплаты",
  "Разовые доплаты",
  "Итого",
];

export const thAllAccrualsArray = [
  "Дата",
  "Сотрудник",
  "Должность",
  "Отдел",
  "Сумма ставок",
  "Оклад",
  "Сделки",
  "Доплаты",
  "Итого",
];
