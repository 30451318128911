import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { Modal, Button, Form, Dropdown, Alert } from "react-bootstrap";

import { CustomButton } from "src/shared/components";
import { useRootStore } from "src/shared/stores/initStore";
import { FormDropdown, FormGroup } from "src/shared/ui/controls";

import style from "./index.module.css";
import { handleTaxpayerIdChange } from "./lib";

export const AddEmployee = observer((props) => {
  const taxPayerIdLength = 12;
  const {
    employmentFormsStore,
    paymentFormsStore,
    departamentStore,
    authStore,
  } = useRootStore();
  const userRole = authStore.userData?.profile?.role;
  const isAccountant = userRole === "accountant";

  const [formData, setFormData] = useState({
    fullname: "",
    nickname: "",
    email: null,
    salary: 0,
    regular_surcharge: 0,
    regular_bets_num: 0,
    regular_bets_price: 0,
    payment_form: null,
    employment_form: null,
    departments: [],
    job_title: "",
    is_fired: false,
    taxpayer_id: null,
    meta_employee: null,
  });
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (props.show) {
      departamentStore.loadDepartaments();
      setFormData({
        fullname: "",
        nickname: "",
        email: null,
        salary: 0,
        regular_surcharge: 0,
        regular_bets_num: 0,
        employment_form: null,
        payment_form: null,
        departments: [],
        job_title: "",
        is_fired: false,
        meta_employee: null,
      });
      employmentFormsStore.loadEmployments();
      paymentFormsStore.loadForms();
      departamentStore.loadDepartaments();
    }
  }, [props.show, employmentFormsStore, paymentFormsStore, departamentStore]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleSelect = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const validateTaxpayerId = (value) => {
    if (!value) return true;
    return value && value.length === taxPayerIdLength;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!validateTaxpayerId(formData.taxpayer_id)) {
      setErrorMessage(`ИНН должен состоять из ${taxPayerIdLength} цифр`);
      return;
    };

    setErrorMessage(null);
    
    const submitData = {
      fullname: formData.fullname,
      nickname: formData.nickname,
      email: formData.email,
      salary: parseFloat(formData.salary),
      regular_surcharge: parseFloat(formData.regular_surcharge),
      regular_bets_num: parseInt(formData.regular_bets_num, 10),
      regular_bets_price: parseFloat(formData.regular_bets_price),
      employment_form_id: formData.employment_form?.id,
      payment_form_id: formData.payment_form?.id,
      department_ids: formData.departments.map((dept) => dept.id),
      job_title: formData.job_title,
      is_fired: formData.is_fired,
      taxpayer_id: formData.taxpayer_id,
      meta_employee: formData.meta_employee,
    };

    props.onSave(submitData);
  };

  const handleSelectDepartment = (eventKey) => {
    const department = departamentStore.departaments.find(
      (dept) => dept.id === eventKey,
    );
    if (
      department &&
      !formData.departments.some((dept) => dept.id === department.id)
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        departments: [...prevFormData.departments, department],
      }));
    }
  };

  const handleRemoveDepartment = (id) => {
    setFormData({
      ...formData,
      departments: formData.departments.filter((dept) => dept.id !== id),
    });
  };

  return (
    <Modal
      className={style.modalContainer}
      show={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Добавление сотрудника</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert
            variant="danger"
            onClose={() => setErrorMessage(null)}
            dismissible
          >
            {errorMessage}
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <FormGroup
            controlId="formFullName"
            label="ФИО"
            type="text"
            name="fullname"
            required
            value={formData.fullname}
            onChange={handleChange}
            placeholder="Введите полное имя"
          />

          <FormGroup
            controlId="formNickname"
            label="Никнейм"
            type="text"
            name="nickname"
            value={formData.nickname}
            onChange={handleChange}
            placeholder="Введите никнейм"
            className={style.styleFormMargin}
          />

          <FormGroup
            controlId="formMetaInfo"
            label="Мета информация"
            type="text"
            name="meta_employee"
            value={formData.meta_employee}
            onChange={handleChange}
            placeholder="Введите мета информацию"
            className={style.styleFormMargin}
          />
          
          <FormGroup
            controlId="formEmail"
            label="Email"
            type="email"
            name="email"
            value={formData.email || ""}
            onChange={handleChange}
            placeholder="Введите email"
            className={style.styleFormMargin}
          />

          {isAccountant && (
            <FormGroup
              controlId="formTaxpayerId"
              label="ИНН"
              type="text"
              name="taxpayer_id"
              value={formData.taxpayer_id || ""}
              onChange={(e) => handleTaxpayerIdChange(e, formData, setFormData, taxPayerIdLength)}
              placeholder="Введите ИНН"
              className={style.styleFormMargin}
              maxLength={taxPayerIdLength}
            />
          )}

          <FormGroup
            controlId="formSalary"
            label="Оклад"
            type="number"
            min="0"
            step="0.01"
            name="salary"
            required
            value={formData.salary}
            onChange={handleChange}
            className={style.styleFormMargin}
          />

          <FormGroup
            controlId="formRegularSurcharge"
            label="Постоянная доплата"
            type="number"
            min="0"
            step="0.01"
            name="regular_surcharge"
            required
            value={formData.regular_surcharge}
            placeholder="Введите доплату"
            onChange={handleChange}
            className={style.styleFormMargin}
          />

          <FormGroup
            controlId="formRegularBetsNum"
            label="Постоянное кол-во ставок"
            type="number"
            min="0"
            name="regular_bets_num"
            value={formData.regular_bets_num}
            onChange={handleChange}
            placeholder="Введите кол-во ставок"
            className={style.styleFormMargin}
          />

          <FormGroup
            controlId="formRegularBetsPrice"
            label="Цена ставки по-умолчанию"
            type="number"
            min="0"
            step="0.01"
            name="regular_bets_price"
            value={formData.regular_bets_price || ""}
            onChange={handleChange}
            placeholder="Введите цену ставки"
            className={style.styleFormMargin}
          />

          <FormGroup
            controlId="formJobTitle"
            label="Должность"
            type="text"
            name="job_title"
            required
            value={formData.job_title}
            onChange={handleChange}
            placeholder="Введите должность"
            className={style.styleFormMargin}
          />

          <FormDropdown
            controlId="formPaymentForm"
            label="Форма выплаты"
            options={paymentFormsStore.forms}
            selectedOption={formData.payment_form}
            onSelect={(selectedOption) =>
              handleSelect("payment_form", selectedOption)
            }
            className={style.styleFormMargin}
            placeholder="Выберите форму выплаты"
          />

          <FormDropdown
            controlId="formEmploymentForm"
            label="Форма занятости"
            options={employmentFormsStore.employments}
            selectedOption={formData.employment_form}
            onSelect={(selectedOption) =>
              handleSelect("employment_form", selectedOption)
            }
            className={style.styleFormMargin}
            placeholder="Выберите форму ТУ"
          />

          <Form.Group
            controlId="formDepartment"
            className={style.styleFormMargin}
          >
            <Form.Label>Отделы</Form.Label>
            <Dropdown onSelect={handleSelectDepartment}>
              <Dropdown.Toggle className={style.dropdownContainer}>
                Выберите отделы
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {departamentStore.departaments.map((department) => (
                  <Dropdown.Item key={department.id} eventKey={department.id}>
                    {department.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <div className={style.fieldForDeportaments}>
              {formData.departments.map((dept) => (
                <span key={dept.id} className={style.selectedDeportations}>
                  {dept.title}
                  <button
                    type="button"
                    className={style.buttonBackground}
                    onClick={() => handleRemoveDepartment(dept.id)}
                  >
                    &#9746;
                  </button>
                </span>
              ))}
            </div>
          </Form.Group>

          <Form.Group
            controlId="formDismissed"
            className={style.styleFormMargin}
          >
            <Form.Check
              type="checkbox"
              label="Уволен"
              name="is_fired"
              checked={formData.is_fired}
              onChange={handleChange}
            />
          </Form.Group>
          <div className={style.btnContainer}>
            <CustomButton onClick={props.onHide}>Закрыть</CustomButton>
            <CustomButton type="submit">Сохранить</CustomButton>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
});
